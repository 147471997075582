@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;500&family=Righteous&family=Shadows+Into+Light&family=Varela+Round&display=swap');

.v2Line {
  margin-top: -10px;
  border-left: 1px solid rgba(60, 7, 146, 0.2);
  height: 75px;
}

@media only screen and (max-width: 600px) {
  .v2Line {
    display: none;
  }
}

.headingH3 {
  font-family: 'Righteous', cursive;
  font-weight: 900;
  color: #8B0000;
}

.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}
.nomoreicon{
 color: #ffb606;
 margin-top: -25px  
}
@media screen and (max-width: 480px) {
  .nomoreicon{
    color: #ffb606;
    margin-top: 0px  
   }
}

/* Mobile Devices (up to 768px) */
@media (max-width: 768px) {
  .Nomorboringcard {
      height: 670px;
    }
}
/* Desktop Devices (769px and above) */
@media (min-width: 769px) {
  .Nomorboringcard{
      height: 562px;
    }
}