.octopusImg {
    width: 130%;
}

.octopusCol {
    margin-top: 50px;
}

@media screen and (max-width: 480px) {
    .octopusImg {
        width: 130%;
    }

    .octopusCol {
        margin-top: 0px;
    }
}