.eventCard {
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    border: none;
    margin-top: 50px;

}

.topeventimg {
    height: 40%;
    width: 90%;
    margin-top: 40px;
    border-radius: 20px;
}

.topeventbtn1 {
    max-width: 50%;
    min-width: 37%;
    background-color: rgb(255, 182, 6) !important;
    border: none !important;
    height: 50px;
    color: #fff; /* Change color to white or any other color */
    font-weight: bold;
    margin-top: 10px;
  }

.topeventbtn2 {
    max-width: 50%;
    min-width: 37%;
    height: 50px;
    color: black;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 30px;
    background-color: #3498db;
}

.animationStudioText {
    margin-top: 20px;
    color: #666666;
    font-weight: bold;
    font-family: DM Sans;
    text-align: left;
}


@media screen and (max-width: 480px) {
    .topeventimg {
        height: 90%;
        width: 80%;
        margin-top: 10px;
        border-radius: 20px;
    }

    .topeventbtn1 {
        max-width: 60%;
        min-width: 38%;
        height: 50px;
        color: black;
        font-weight: bold;
        margin-top: 10px;
        margin-left: 30px;
    }

    .topeventbtn2 {
        max-width: 60%;
        min-width: 38%;
        height: 50px;
        color: black;
        font-weight: bold;
        margin-top: 10px;
        background-color: #3498db;
    }

    .animationStudioText {
        text-align: center;
    }
}