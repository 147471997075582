.FeaturesCardImg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;


}

.FeaturesCard {
  border-color: #fff;
}

.aboutUsContainer {
  margin-top: 40px;
}

.aboutUsIntro {
  margin-top: 25px;
}

.aboutUsIconContainer {
  margin-top: 0px;
}

.introductionP {
  color: #666666;
  font-size: 22px;
  font-family: DM Sans;
}

.introductionWelcome {
  color: #002147;
  font-weight: bold;
  font-family: DM Sans;
}

.introductionText {
  color: #666666;
  line-height: 1.7;
  font-weight: 400px;
  font-size: 19px;
  font-family: DM Sans;
}

.introductionTextMore {
  color: #666666;
  line-height: 1.7;
  font-weight: 400px;
  font-size: 19px;
  font-family: DM Sans;
}

@media only screen and (max-width: 600px) {
  .FeaturesCard {
    text-align: center;
  }

  .aboutUsContainer {
    margin-top: -30px;
  }

  .aboutUsIntro {
    margin-top: -70px;
  }

  .aboutUsIconContainer {
    margin-top: -20px;
  }

  .introductionP {
    text-align: center;
    margin-top: 30px !important;
  }

  .introductionWelcome {
    text-align: center;
  }

  .introductionText {
    text-align: center;
  }

  .introductionTextMore {
    text-align: center;
  }


}