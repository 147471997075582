.CardCourses {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  margin-bottom: 20px;
}
/* Mobile Devices (up to 768px) */
@media (max-width: 768px) {
    .popcard {
        height: 900px;
      }
}
/* Desktop Devices (769px and above) */
@media (min-width: 769px) {
    .popcard {
        height: 562px;
      }
}

