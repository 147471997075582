.galleryPic {
    height: 260px;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
}

.galleryPicCol {
    margin-top: 0px;
}



@media screen and (max-width: 480px) {
    .galleryPic {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }

    .galleryPicCol {
        margin-top: 20px;
    }

}