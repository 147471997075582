.FeaturesCard2 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-bottom: 40px;
    border-radius: 4px;
}

@media only screen and (max-width: 600px) {
    .FeaturesCard2 {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        margin-bottom: 40px;
        border-radius: 4px;
        text-align: center;
    }
}

.topImg {
    transition: transform .7s ease-in-out;
}

.topImg:hover {
    transform: rotate(360deg);
}

.boxMain {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
}

.boxImg {
    width: 100%;
    height: 100%;
}

.boxContent {
    background-color: #ffc107;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin-top: 250px;
    transition: all 1s ease-in-out;
    font-weight: bold
}

.boxMain:hover .boxContent {
    margin-top: 0px;
}
/* Mobile Devices (up to 768px) */
@media (max-width: 768px) {
    .topcard {
        height: 650px;
      }
}
/* Desktop Devices (769px and above) */
@media (min-width: 769px) {
    .topcard {
        height: 525px;
      }
}
