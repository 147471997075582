@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins:wght@300&display=swap');

@media screen and (min-width: 768px) {
  .headerLink {
    padding-left: 10px;
    font-family: DM Sans;
    font-size: 17px;
    color: #323232;
    font-weight: 700 !important;
    display: inline-block;
    position: relative;
    text-decoration: none;
    background-color: transparent;
  }
}

.topHeader {
  background-color: #182143;
}

.topHeaderText2 {
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
  position: relative;
  list-style: none;
  margin-top: -15px;
  font-size: 15px;
  font-weight: 500;
}

.topHeaderText1 {
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
  position: relative;
  list-style: none;
  margin-top: 10px;
  font-weight: bold;
}

.headerLink {
  padding-left: 20px !important;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-size: 12px !important;
  color: #201e1e !important;
  font-weight: bolder !important;
  background-color: transparent;
}

.headerLink2 {
  padding-left: 20px !important;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-size: 12px !important;


  color: #201E1E !important;
  font-weight: bolder !important;
  background-color: transparent;
}


.headerLink::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #88C417;
  transition: width .3s;
}

.headerLink:hover::after {
  width: 100%;
  color: #88C417;

}

/* .headerLink:hover {
    border-bottom: 3px solid red;
  } */



/* .navbar {
      -webkit-box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
      -moz-box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
      box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
    } */

.navbar-nav {
  padding-top: 10px;
  padding-bottom: 10px;
}


/* .navbar-light .navbar-nav .nav-link{
      transition: background-color 1.5s, color 1s;
      padding: 10px;
    }
    .navbar-light .navbar-nav .nav-link:hover{
      color: #1abc9c;
      color: red;
    }
      */
.navbar-light .navbar-nav .nav-link {
  color: black;
}

.btnheader {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: inline-block;
  text-decoration: none;
  font-size: inherit;
  width: '100%';
  background-color: #ffc107;
  border: 0;
  border-radius: 4px;
  font-family: DM Sans;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.drop-down-link {
  color: #7e7e7e;
  font-weight: 500;
  border-bottom: 1px solid gray;
  transition: all 1s;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  font-size: 12px;
  padding: 1px;
}

.drop-down-link:hover {
  background-color: #182143;
  color: white;
}

.vLine {
  margin-top: 15px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 45px;
}

.drpdown {
  width: 400px;
}

.imglogoscreen {
  width: 100%;
  height: 90%;
}

.navbarDiv {
  width: 500px;
}

@media screen and (max-width: 480px) {
  .drpdown {
    width: 100%;
  }

  .imglogoscreen {
    width: 100%;
    height: 100%;
  }

  .drop-down-link {
    font-size: 12px;
  }


  .navbarDiv {
    width: 100%;
  }
}