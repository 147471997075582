@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&family=Roboto:wght@300&display=swap');

.intro {
  display: table;
  width: 100%;
  padding: 0;
  background: url('../Images/banner/11.jpg') center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  /* animation: zoominoutsinglefeatured 20s infinite; */
  -o-background-size: cover;
  height: 80%;

}

.intro2 {
  display: table;
  width: 100%;
  padding: 0;
  background: url('../Images/banner/22.jpg') center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  /* animation: zoominoutsinglefeatured 20s infinite; */
  -o-background-size: cover;
  height: 80%;
}

.intro3 {
  display: table;
  width: 100%;
  padding: 0;
  background: url('../Images/banner/33.jpg') center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  /* animation: zoominoutsinglefeatured 20s infinite; */
  -o-background-size: cover;
  height: 80%;
}

.intro4 {
  display: table;
  width: 100%;
  padding: 0;
  background: url('../Images/banner/44.jpg') center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  /* animation: zoominoutsinglefeatured 20s infinite; */
  -o-background-size: cover;
  height: 80%;
}

.intro5 {
  display: table;
  width: 100%;
  padding: 0;
  background: url('../Images/banner/33.jpg') center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  /* animation: zoominoutsinglefeatured 20s infinite; */
  -o-background-size: cover;
  height: 80%;
}

.intro6 {
  display: table;
  width: 100%;
  padding: 0;
  background: url('../Images/banner/uiux.png') center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  /* animation: zoominoutsinglefeatured 20s infinite; */
  -o-background-size: cover;
  height: 80%;
}

.intro7 {
  display: table;
  width: 100%;
  padding: 0;
  background: url('../Images/banner/3.png') center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  /* animation: zoominoutsinglefeatured 20s infinite; */
  -o-background-size: cover;
  height: 80%;
}

.intro8 {
  display: table;
  width: 100%;
  padding: 0;
  background: url('../Images/banner/hashtag\ skool.png') center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  /* animation: zoominoutsinglefeatured 20s infinite; */
  -o-background-size: cover;
  height: 80%;
}


.carousel-control-prev-icon {
  display: none;
}

.carousel-control-next-icon {

  display: none;
}

.carousel-indicators {
  color: #5ca9fb;
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}




.intro h1 {
  font-family: "DM Sans";
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  animation: none !important;
}

.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}

.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}

header .intro-text {
  padding-top: 180px;
  padding-bottom: 100px;
  text-align: center;
  margin-top: -80px;
}

.form-div {

  background-size: 40px 40px;
  background-position: 1%;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  height: 60px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid var(--theme-ui-colors-primary, #8D448B);
  padding: 4px 25px 0px 50px;
  position: relative;
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 2.5;
  font-family: DM Sans;
  color: #0F2137;
}

#button {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  text-align: center;
  line-height: inherit;
  text-decoration: none;
  border: 0;
  background-color: var(--theme-ui-colors-primary, #e90a0a);
  font-family: DM Sans;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0 30px;
  transition: 0.3s ease-in-out 0s;
  white-space: nowrap;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  min-height: 50px;
  width: 50%;
  margin-bottom: 20px;
}

#button:hover {
  background-color: rgb(59, 59, 59);
}


#hero_text {}

#hero_text h2 {
  font-size: 80px;

  font-weight: 1000;
  line-height: 100px;
  margin-bottom: 65px;
}


@media only screen and (max-width: 600px) {
  .intro-text {
    height:50%
  }
}