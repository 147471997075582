.cardImg{
    height:100%;
    border-radius: 15px;
    padding-bottom: 10px;
}


@media only screen and (max-width: 600px) {
    .textonCard{
        text-align: center;
        margin-top: 10px;
    }
  }

