.videostyle {
    height: 4000px;
}

.whyChooseContainer {
    margin-top: 40px;
    background-color: #DCD6DD;
}



@media only screen and (max-width: 600px) {
    .whyChooseContainer {
        margin-top: 20px;
    }
}