#contactCard{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  
    margin-bottom: 40px;
   
}

.contactCardImg{
    width: 110px;
    height: 110px;
}