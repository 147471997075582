
.intro3 {
    display: table;
    width: 100%;
    padding: 0;
    background: url('../Images/carousel-1.jpg') center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;  
    -o-background-size: cover;
    height: 100%;


  
  }

.areacontainer{
    width: 100%;
    left: 0;
    right: 0;
    font-family: Roboto,sans-serif;
    background: rgba(26, 27, 37, 0.75);
    border-radius: 3px;
    padding: 15px;

}