.teamimgGupul {
    height: 160px;
    width: 140px !important;
    margin-top: 8px;
    border-bottom: 3px solid #000;
}

.teamimgRumi {
    height: 160px;
    width: 140px !important;
    margin-top: 8px;
    border-bottom: 3px solid #000;
}

.teamimgAnurag {
    height: 160px;
    width: 140px !important;
    margin-top: 8px;
    border-bottom: 3px solid #000;
}

.expBox {
    background-color: #ecf0f1;
    margin-top: 110px;
}

@media screen and (max-width: 480px) {
    .teamimgGupul {
        width: 130px;
        height: 150px;
        margin-top: 10px;
    }

    .teamimgRumi {
        width: 130px;
        height: 150px;
        margin-top: 10px;
    }

    .teamimgAnurag {
        width: 130px;
        height: 150px;
        margin-top: 10px;
    }

    .expBox {
        background-color: #ecf0f1;
        margin-top: 0px;
    }
}